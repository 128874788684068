import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import { FaStar } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import { imageAssets } from '../images';

function TestimonialSection() {
  return (
    <div className=' dad_con ' >
    <div className='div_for_pad_t'>
      <div className='cstmrs-titl'>
        <h3 className='bef_elem'>What our customers say</h3>
      </div>
      <div className='motivte_flex_row' >
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="cstmr-slider-con">
              <div className='sldr_media' >
                <div className='pro-pic' >
                  <img src={imageAssets.testimonial1} alt="" />
                </div>
                <div className='pro_pic_inf' >
                  <p>Nitin</p>
                  <span>Delhi</span>
                </div>
                <div className='test-cmmnt' >
                  <FaQuoteRight />
                </div>
              </div>
              <div className='test_sld' >
                <p>CARS360 has procured quality cars for our business and showed excellent level of service. Deliveries are always on the dot and the turn around time to resolve an issue is impressive</p>
                <span><FaStar /> <FaStar /> <FaStar /> <FaStar /><FaStar /></span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cstmr-slider-con">
              <div className='sldr_media' >
                <div className='pro-pic' >
                  <img src={imageAssets.testimonial3} alt="" />
                </div>
                <div className='pro_pic_inf' >
                  <p>Raj</p>
                  <span>Ghaziabad</span>
                </div>
                <div className='test-cmmnt' >
                  <FaQuoteRight />
                </div>
              </div>
              <div className='test_sld' >
                <p>The best aspect about CARS360 is the smooth process they follow - starting from the inspection procedure to the delivery of cars. I laud the excellent wide variety of services that CARS360 has to offer, ensuring growth of our business.</p>
                <span><FaStar /> <FaStar /> <FaStar /> <FaStar /><FaStar /></span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cstmr-slider-con">
              <div className='sldr_media' >
                <div className='pro-pic' >
                  <img src={imageAssets.testimonial4} alt="" />
                </div>
                <div className='pro_pic_inf' >
                  <p>Binay Kumar</p>
                  <span>New Delhi</span>
                </div>
                <div className='test-cmmnt' >
                  <FaQuoteRight />
                </div>
              </div>
              <div className='test_sld' >
                  <p>CARS360 for the success of my business - the size of my counter has just doubled. Now, I have access to over 150 cars daily from across India. Procurement of cars from my counter has become all the more convenient and hassle-free.</p>
                <span><FaStar /> <FaStar /> <FaStar /> <FaStar /><FaStar /></span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="cstmr-slider-con">
              <div className='sldr_media' >
                <div className='pro-pic' >
                  <img src={imageAssets.testimonial2} alt="" />
                </div>
                <div className='pro_pic_inf' >
                  <p>Amit Jain</p>
                  <span>Delhi</span>
                </div>
                <div className='test-cmmnt' >
                  <FaQuoteRight />
                </div>
              </div>
              <div className='test_sld' >
                <p>The amicable company employees are always responsive and willing to serve in the best way possible</p>
                <span><FaStar /> <FaStar /> <FaStar /> <FaStar /><FaStar /></span>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
  )
}

export default TestimonialSection
