import React  from 'react'
import { NavLink  } from 'react-router-dom'

function Header() {
  return (
    // <div>
    //    <div className='tob-bar-nav' >
    //     <div className='strt-nav' >
    //       <img src="./images/cars360-logo.png" alt="" />
    //     </div>

    //     <div className='mid-menu-nav' >
    //       <ul>
    //         <li className='list-item-inner' > <NavLink exact activeClassName="active" to="/">SELL YOUR CAR</NavLink > </li>
    //         <li className='list-item-inner' > <NavLink exact activeClassName="active" to="/about">ABOUT US</NavLink > </li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
    
  <div className='main-div'>
  <div class="container-fluid main-container">
  <div class="row">
    <div class="col-md-2 logo-container">
      <img style={{width:'70%'}} src="./images/cars360-logo.png" alt="" />
      <span className="premium-txt">Premium</span>
    </div>
    <div class="col-md-8">
    <ul className='navbar1'>
            <li className='list-item-inner' > <NavLink exact activeClassName="active" to="/">SELL YOUR CAR</NavLink > </li>
             <li className='list-item-inner' > <NavLink exact activeClassName="active " to="/about" >ABOUT US</NavLink > </li>
            </ul>
    </div>
    <div class="col-md-2">
     
    </div>
  </div>
</div>
</div>
  )
}

export default Header
