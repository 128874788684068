import React from 'react'
import { Link } from "react-router-dom";

import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

function Footer() {
    return (
            <div className=' dad_con ' >
                <div className='div_for_pad_f'>
                    <div className="footr_con">
                        <div className="footr_">
                            <div>
                                <h3>CARS360</h3>
                                <p>We are an experienced player in the used car trading domain, operationg for over a decade now. We are enabling car owners to easily sell their cars through our secure and hassle-free process.</p>
                            </div>
                        </div>
                        <div className="footr_  scnd_ftr">
                            <div className='ft_in' >
                                <h3>COMPANY</h3>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="about">About Us</Link></li>
                                    <li><a target="_blank" href="https://blog.cars360.co/">Blog</a></li>
                                    <li><a target="_blank" href="https://cars360.co/terms-conditions.php">Terms & Condition</a></li>
                                    <li><a target="_blank" href="https://cars360.co/privacy.php">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footr_">
                            <div className='ft_in' >
                                <h3>GET IN TOUCH WITH US</h3>
                                <ul>
                                    <li><a href="#">Sellers</a></li>
                                    <div className='cntct_name' >
                                        <span><FaPhoneAlt /> <a href="tel:+919910080365">9910080365</a></span> <br />
                                        <span><FaEnvelope /> <a href="mailto:care@cars360.co">care@cars360.co</a></span> <br />
                                        <span><FaWhatsapp /> <a target="_blank" href="https://api.whatsapp.com/send?phone=+919910080365&text=I%20am%20interested%20in%20your%20service">Chat With Us!</a></span>
                                    </div>
                                    <li><a href="#">Buyers</a></li>
                                    <div className='cntct_name' >
                                        <span><FaPhoneAlt /> <a href="tel:+919910080365">9910080365</a></span> <br />
                                        <span><FaEnvelope /> <a href="mailto:care@cars360.co">care@cars360.co</a></span> <br />
                                        <span><FaWhatsapp /> <a target="_blank" href="https://api.whatsapp.com/send?phone=+919910080365&text=I%20am%20interested%20in%20your%20service">Chat With Us!</a></span>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="footr_">
                            <div className='ft_in' >
                                <h3>Other Services</h3>
                                <ul>
                                    <li><a target="_blank" href="https://cars360.co/partner.php">Become Our Partner</a></li>
                                    <li><a target="_blank" href="https://www.cars360.co/loan.php" >Car Loan</a></li>
                                    <li><a href="/">Book a Free Inspection</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_l">
                    <span>copyright © {new Date().getFullYear()} Cars360 | All rights reserved</span>
                </div>
            </div>
    )
}

export default Footer
