import React, { useEffect, useState, useRef } from 'react'
import '../../src/App.css';
import '../../src/style.css';
import '../../src/responsive.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { Modal } from 'reactstrap'
import { ModalHeader } from 'reactstrap'
import { ModalBody } from 'reactstrap'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useGeolocated } from "react-geolocated";
import { FaWhatsapp } from 'react-icons/fa'
import { FaArrowLeft } from 'react-icons/fa'
import img2 from '../images/home-img.jpg'
import Header from '../component/Header';
import Footer from '../component/Footer';
import BlogSection from '../component/BlogSection';
import TestimonialSection from '../component/TestimonialSection';
import { useLocation, useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import { getModels, getBrands, getFuels, getStates, getCities, getVariant, listRto, sendCarEnquiry, sendOtpToMobile, verifyOtpMobile } from '../redux/siteAction';
import { API_BASE_URL, ENVIRONMENT, GA_AD_CONVERSION_TAG, GA_AW_CODE, GMAPS_KEY } from '../env';
import { useDispatch, useSelector } from 'react-redux';
import { initStateData, updateSellCarData } from '../redux/SellCarSlice';
import CarsDetailsSection from '../component/CarsDetailsSection';
import { Helmet } from "react-helmet";
import { FaCheck } from 'react-icons/fa';
import axios from 'axios';

function LandingPages() {

  const navigate = useNavigate();

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  Geocode.setApiKey(GMAPS_KEY);

  let [states, setStates] = useState([]);
  let [cities, setCities] = useState([]);
  let [brands, setBrands] = useState([]);
  let [models, setModels] = useState([]);
  let [variants, setVariants] = useState([]);
  let [fuels, setFuels] = useState([]);
  const [modal, setmodal] = useState(false);
const formObj = {
  name: '',
  phone: '',
  email: '',
  subject: 'Landing Page Enquiry',
  message: '',
}
  const [contactDetail, setContactDetail] = useState(formObj)

const setContactValue = (e) => {
  setContactDetail((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      
  }))
}

const sumbitContactForm =  async() =>{
  
  const payload = {...contactDetail}
  try {
    const data = await axios.post(API_BASE_URL + '/contact-us', payload);
    toast.success(data.data.message)
    setContactDetail(formObj)
    return data.data; 
    
} catch (error) {
    let message = 'Something went wrong, please try again!';
    let status_code = 500;
    if (error.response) {
        message = error.response.data.message;
        status_code = error.response.status;
    }
    toast.error(message);
    return {
        'success': false,
        'message': message,
        'status_code': status_code
    }
}

}

  let [sellCarTabs, setSellCarTabs] = useState([
    'Brand',
    'Year',
    'Model',
    'Fuel',
    'Variant',
    'State',
    'City',
    'RTO',
    'Kms',
    'Owner'
  ]);

  let selectedData = useSelector(state => state.sellCarData);
  let dispatch = useDispatch();

  let [ownerList, setOwnerList] = useState([
    { value: "1", label: '1st Owner' },
    { value: "2", label: '2nd Owner' },
    { value: "3", label: '3rd Owner' },
    { value: "4", label: '4th Owner' },
    { value: "5", label: '5th Owner' },
    { value: "6", label: '6th Owner' },
  ]);

  const [yearList, setYearList] = useState([]);

  let [kilometerList, setKilometerList] = useState([
    { value: "0-10000", label: '0-10K' },
    { value: "10000-30000", label: '10K-30K' },
    { value: "30000-60000", label: '30K-60K' },
    { value: "60000-120000", label: '60K-120K' },
    { value: "120000-above", label: '120K-above' },
  ]);

  const { pathname } = useLocation();

  useEffect(() => {
    document.title = 'Cars360 | Home - Sell Used Car | Best Value Of Used Car | Sell Now';
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getRtoList();
    getStates(101).then(stateList => {
      if (stateList.success) {
        setStates(stateList.data);
        setFilteredStates(stateList.data);
        if (selectedData.state == null) {
          if (coords) {
            updateGeocode(stateList.data);
          }
        } else {
          handleSelectState(selectedData.state, selectedData.city_name)
        }
      }
    }).catch(err => {
      console.log(err);
    });
    getBrands('is_premium=1').then(brandlist => {
      if (brandlist.success) {
        const filterData = brandlist.data;
        setBrands(filterData);
        setFilteredBrand(filterData);
        if (selectedData.brand != null) {
          handleSelectBrand(selectedData.brand, selectedData.brand_name, false);
        }
      }
    }).catch(err => {
      console.log(err);
    });
    if (selectedData.model != null) {
      handleSelectModel(selectedData.model, selectedData.model_name, false);
    }
    if (selectedData.variant != null) {
      handleSelectVariant(selectedData.variant, selectedData.variant_name, false);
    }
    getFuels('').then(fuelList => {
      if (fuelList.success) {
        setFuels(fuelList.data);
        setFilteredFuels(fuelList.data);
      }
    }).catch(err => {
      console.log(err);
    });

    // let currentYear = new Date().getFullYear();
    let years = [];
    for (let index = new Date().getFullYear(); index >= 2012; index--) {
      years.push(index)
    }
    setYearList(years);
  }, [coords]);

  useEffect(() => {
    swapIndex(states, 'id', selectedData.state);
    swapIndex(cities, 'id', selectedData.city);
    swapIndex(brands, 'id', selectedData.brand);
    swapIndex(models, 'id', selectedData.model);
    swapIndex(variants, 'id', selectedData.variant);
    swapIndex(fuels, 'id', selectedData.fuel);
    swapIndex(yearList, '', selectedData.year);
    swapIndex(kilometerList, 'value', selectedData.km_driven);
    swapIndex(ownerList, 'value', selectedData.owner);
    swapIndex(rtoCars, 'id', selectedData.rto);
  }, [selectedData])

  const updateGeocode = (states = []) => {
    Geocode.fromLatLng(coords.latitude, coords.longitude).then(
      (response) => {
        let city, state, country;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        states.map((stateData) => {
          if (stateData.name == state) {
            handleSelectState(stateData.id, city);
          }
        })
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const swapIndex = (arrData, key, val) => {
    let newInd = 0;

    if (arrData.length <= 0) {
      return arrData;
    }

    arrData.map((item, index) => {
      if (key == '') {
        if (item == val) {
          newInd = index;
        }
      } else {
        if (item[key] == val) {
          newInd = index;
        }
      }
    });

    let swap = arrData[newInd];
    arrData[newInd] = arrData[0];
    arrData[0] = swap;

    return arrData;
  }

  const handleSelectState = (state_id, cityname = '') => {
    let newSelectedData = {
      ...selectedData,
      'state': state_id,
      'rto': (cityname != '') ? selectedData.rto : null
    }

    dispatch(updateSellCarData(newSelectedData));

    getCities(state_id).then(cityData => {
      if (cityData.success) {
        setCities(cityData.data);
        setFilteredCity(cityData.data);
        if (cityname != '') {
          cityData.data.map((citydet) => {
            if (citydet.name == cityname) {
              newSelectedData = {
                ...newSelectedData,
                'city': citydet.id,
                'city_name': citydet.name
              };
              dispatch(updateSellCarData(newSelectedData));
            }
          })
        }
        // Switching Tabs From State to City -->
        if (cityname == '') {
          handleSearchState('');
          handleHorizantalScroll(elementRef.current, 25, 130, 10);
          selectTab(newSelectedData.current_step + 1, newSelectedData);
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  // State Search 
  const [filteredstates, setFilteredStates] = useState([]);
  const handleSearchState = (searchValue) => {
    let filtered = states.filter(states => states.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredStates(filtered)
  }

  const handleSelectCities = (data) => {
    const city_id = data.target.getAttribute("data-cities_id");
    const city_name = data.target.getAttribute("data-cities_name");
    let newSelectedData = {
      ...selectedData,
      'city': city_id,
      'city_name': city_name
    };
    dispatch(updateSellCarData(newSelectedData));

    // Switching Tabs From City to Kms Driven  -->
    handleSearchCity('');
    handleHorizantalScroll(elementRef.current, 25, 130, 10);
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  }

  // City Search 
  const [filteredcity, setFilteredCity] = useState([]);

  const handleSearchCity = (searchValue) => {
    let filtered = cities.filter(cities => cities.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredCity(filtered)
  }

  const handleSelectBrand = (brand_id, brand_name, switchTab = true) => {

    let newSelectedData = {
      ...selectedData,
      'brand': brand_id,
      'brand_name': brand_name,
      'variant': null,
      'model': null
    }
    dispatch(updateSellCarData(newSelectedData));

    getModels(`brand_id=${brand_id}&is_premium=1`).then(modellistData => {
      if (modellistData.success) {
        setModels(modellistData.data);
        setFilteredModels(modellistData.data);
        console.log(modellistData.data.filter((val)=>val.is_premium === 1));
        if (switchTab) {
          // Switching Tabs From Brand to Year
          handleSearchBrands('');
          handleHorizantalScroll(elementRef.current, 25, 130, 10);
          selectTab(newSelectedData.current_step + 1, newSelectedData);
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  //  Brands Search
  const [filteredBrand, setFilteredBrand] = useState([]);

  const handleSearchBrands = (searchValue) => {
    let filtered = brands.filter(brands => brands.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredBrand(filtered)
  }

  const handleSelectModel = (model_id, model_name, switchTab = true) => {
    let newSelectedData = {
      ...selectedData,
      'model': model_id,
      'model_name': model_name,
      'variant': null
    }
    dispatch(updateSellCarData(newSelectedData));

    let modelId = model_id;
    getVariant(modelId, selectedData.fuel).then(variantlistData => {
      if (variantlistData.success) {
        setVariants(variantlistData.data);
        setFilteredVariants(variantlistData.data);
        if (switchTab) {
          // Switching Tabs From Models to Variant --->
          handleSearchModel('');
          handleHorizantalScroll(elementRef.current, 25, 130, 10);
          selectTab(newSelectedData.current_step + 1, newSelectedData);
        }
      }
    }).catch(err => {
      console.log(err);
    });
    getFuels(modelId).then(fuelList => {
      if (fuelList.success) {
        if (fuelList.data.length > 0) {
          setFuels(fuelList.data);
          setFilteredFuels(fuelList.data);
        } else {
          getFuels('').then(fuelList => {
            if (fuelList.success) {
              setFuels(fuelList.data);
              setFilteredFuels(fuelList.data);
            }
          }).catch(err => {
            console.log(err);
          });
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  //  Models Search
  const [filteredModels, setFilteredModels] = useState([]);

  const handleSearchModel = (searchValue) => {
    let filtered = models.filter(models => models.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredModels(filtered)
  }

  const handleSelectVariant = (variant_id, variant_name, switchTab = true) => {
    let newSelectedData = {
      ...selectedData,
      'variant': variant_id,
      'variant_name': variant_name
    }
    dispatch(updateSellCarData(newSelectedData));

    if (switchTab) {
      // Tabs Switching from  Variant to Fuel -->
      handleSearchVariant('');
      handleHorizantalScroll(elementRef.current, 25, 130, 10);
      selectTab(newSelectedData.current_step + 1, newSelectedData);
    }
  }

  //  Variants Search

  const [filteredVariants, setFilteredVariants] = useState([]);

  const handleSearchVariant = (searchValue) => {
    let filtered = variants.filter(variants => variants.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredVariants(filtered);
  }

  const handleSelectFuels = (fuel_id) => {
    let newSelectedData = {
      ...selectedData,
      'fuel': fuel_id,
    };
    dispatch(updateSellCarData(newSelectedData));

    let fuelId = fuel_id;
    getVariant(selectedData.model, fuelId).then(variantlistData => {
      if (variantlistData.success) {
        if (variantlistData.data.length > 0) {
          setVariants(variantlistData.data);
          setFilteredVariants(variantlistData.data);
        } else {
          getVariant(selectedData.model, '').then(variantlistData => {
            if (variantlistData.success) {
              setVariants(variantlistData.data);
              setFilteredVariants(variantlistData.data);
            }
          }).catch(err => {
            console.log(err);
          });
        }
      }
    }).catch(err => {
      console.log(err);
    });

    // Tabs Switching from  Fuel to RTO   -->
    handleHorizantalScroll(elementRef.current, 25, 130, 10);
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  }

  // Fuels Search 

  const [filteredFuels, setFilteredFuels] = useState([]);

  const handleSearchFuels = (searchValue) => {
    let filtered = fuels.filter(fuels => fuels.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredFuels(filtered);
  }

  const handleSelectYear = (data) => {
    let newSelectedData = {
      ...selectedData,
      'year': data.target.innerText
    };
    dispatch(updateSellCarData(newSelectedData));

    // Tabs Switching from Year to Modal -->
    handleHorizantalScroll(elementRef.current, 25, 130, 10);
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  }

  // year Search 
  const handleSearchYear = (data) => {
    let searchYear = data.target.value;
    let filtered = yearList.filter(item => item.includes(searchYear));
    setYearList(filtered)
  }

  // kms Search
  const handleKilometerChange = (data) => {
    const kmsDriv_id = data.target.getAttribute("data-kms_id");
    let newSelectedData = {
      ...selectedData,
      'km_driven': kmsDriv_id
    };
    dispatch(updateSellCarData(newSelectedData));

    // Switching Tabs From Kms Driven to Owners List  -->
    handleHorizantalScroll(elementRef.current, 25, 130, 10);
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  };

  // Owner Search
  const handleOwnerChange = (data) => {
    const OwnerSerial_id = data.target.getAttribute("data-owner_id");
    const OwnerSerial_label = data.target.getAttribute("data-owner_label");
    let newSelectedData = {
      ...selectedData,
      'owner': OwnerSerial_id,
      'owner_serial_name': OwnerSerial_label
    };
    dispatch(updateSellCarData(newSelectedData));
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  };

  const [rtoCars, setRtoCars] = useState([]);
  const getRtoList = () => {
    listRto().then(rtoData => {
      if (rtoData.success) {
        if (rtoData.data.data.length > 0) {
          setRtoCars(rtoData.data.data);
          setFilteredRto(rtoData.data.data);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const handleSelectRto = (data) => {
    const rto_listId = data.target.getAttribute("data-rtolist_id");
    const rto_listName = data.target.getAttribute("data-rtolist_name");
    let newSelectedData = {
      ...selectedData,
      'rto': rto_listId,
      'rto_name': rto_listName
    };
    dispatch(updateSellCarData(newSelectedData));

    // Tabs Switching from Rto to State -->
    handleSearchRto('');
    handleHorizantalScroll(elementRef.current, 25, 130, 10);
    selectTab(newSelectedData.current_step + 1, newSelectedData);
  }

  // RTO Search 
  const [filteredRto, setFilteredRto] = useState([]);

  const handleSearchRto = (searchValue) => {
    let filtered = rtoCars.filter(rtoCars => rtoCars.rto_code.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredRto(filtered);
  }

  const validateEnqForm = () => {
    let hasErrors = false;
    if (selectedData.state == null) {
      hasErrors = true;
      toast.error('Please select your current state');
    }
    else if (selectedData.city == null) {
      hasErrors = true;
      toast.error('Please select your current city');
    }
    else if (selectedData.brand == null) {
      hasErrors = true;
      toast.error('Please select your car\'s brand');
    }
    else if (selectedData.model == null) {
      hasErrors = true;
      toast.error('Please select your car\'s model');
    }
    else if (selectedData.variant == null) {
      hasErrors = true;
      toast.error('Please select your car\'s variant');
    }
    else if (selectedData.fuel == null) {
      hasErrors = true;
      toast.error('Please select your car\'s fuel type');
    }
    else if (selectedData.year == null) {
      hasErrors = true;
      toast.error('Please select your car\'s purchase year');
    }
    else if (selectedData.owner == null) {
      hasErrors = true;
      toast.error('Please select your owner serial');
    }
    else if (selectedData.rto == null) {
      hasErrors = true;
      toast.error('Please select your car\'s RTO');
    }
    else if (selectedData.km_driven == null) {
      hasErrors = true;
      toast.error('Please select KM driven');
    }
    else if (!hasErrors) {
      setmodal(true);
    }
  }

  let [mobile, setMobile] = useState('');

  let validateMobile = () => {
    let hasErrors = false;
    if (mobile == null || mobile == '') {
      hasErrors = true;
      toast.error('Please enter your mobile number');
    }
    else {
      let filter = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/;
      if (filter.test(mobile) && mobile !== 9999999999 && mobile !== 8888888888 && mobile !== 7777777777) {
        if (mobile.length !== 10) {
          hasErrors = true;
          toast.error('Please enter a valid 10 digit mobile number');
        }
      }
      else {
        hasErrors = true;
        toast.error('Please enter a valid mobile number');
      }
    }
    return hasErrors;
  }

  const sendLeadEnquiry = () => {
    let postData = {
      "lead_id": selectedData.lead_id,
      "brand_id": selectedData.brand,
      "model_id": selectedData.model,
      "varient_id": selectedData.variant,
      "state_id": selectedData.state,
      "city_id": selectedData.city,
      "fuel_id": selectedData.fuel,
      "rto_id": selectedData.rto,
      "make_year": selectedData.year,
      "km_driven": selectedData.km_driven,
      "owner_serial": selectedData.owner,
      "mobile": mobile,
      "lat": coords?.latitude,
      "lng": coords?.longitude,
      "registration_no": selectedData.reg_no
    };

    sendCarEnquiry(postData).then((res) => {
      if (res.success) {

        let newSelectedData = {
          ...selectedData,
          'lead_id': res.data.id
        };

        dispatch(updateSellCarData(newSelectedData));

      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      toast.error('Something went wrong, please try again later');
      console.log(err);
    });
  }

  const verifyOtp = () => {
    let hasErrors = validateMobile();

    const data = {
      "lead_id": selectedData.lead_id,
      "brand_id": selectedData.brand,
      "brand_name": selectedData.brand_name,
      "model_id": selectedData.model,
      "model_name": selectedData.model_name,
      "varient_id": selectedData.variant,
      "varient_name": selectedData.variant_name,
      "make_year": selectedData.year,
      "make_yename": selectedData.year,
      "rto_id": selectedData.rto,
      "rto_name": selectedData.rto_name,
      "owner_serial": selectedData.owner,
      "owner_seriname": selectedData.owner_serial_name,
      "km_driven": selectedData.km_driven,
      "km_drivname": selectedData.km_driven,
      "state_id": selectedData.state
    };

    if (otpNum == null || otpNum == '') {
      hasErrors = true;
      toast.error('Please enter OTP sent on your mobile number');
    }

    if (!hasErrors) {
      verifyOtpMobile(mobile, otpNum, selectedData.lead_id).then((res) => {
        if (res.success) {
          if (ENVIRONMENT == 'production') {
            window.gtag('event', 'conversion', { 'send_to': GA_AD_CONVERSION_TAG });
          }
          dispatch(updateSellCarData(initStateData));
          navigate("/thankyou", { state: { data } });
        } else {
          toast.error(res.message);
        }
      }).catch((err) => {
        toast.error('Something went wrong, please try again later');
        console.log(err);
      })
    }
  }

  let [otpNum, setOtpNum] = useState('');
  let [otpLabel, setOtpLabel] = useState('Send OTP');

  const sendOtp = () => {
    if (!validateMobile()) {
      sendOtpToMobile(mobile).then((data) => {
        if (data.success) {
          setOtpLabel('Resend OTP');
          toast.success(data.message);
          sendLeadEnquiry();
        } else {
          toast.error(data.message);
        }
      }).catch((err) => {
        toast.error("Something went wrong. Please try again later!");
      })
    }
  }

  let [rtoSearchTxt, setRtoSearchTxt] = useState('');

  const selectTab = (tabIndex, updatedCarData) => {
    if (sellCarTabs.length > tabIndex) {
      dispatch(updateSellCarData({ ...updatedCarData, 'current_step': tabIndex }));
    }
    if (sellCarTabs[tabIndex] == 'RTO') {
      let rtoSearch = '';

      if (updatedCarData.rto == '' || updatedCarData.rto == null) {
        rtoCars.map((rtoData) => {
          if (rtoData.state_id == updatedCarData.state) {
            rtoSearch = rtoData.rto_code.substring(0, 2);
          }
        })
      }

      setRtoSearchTxt(rtoSearch);
      handleSearchRto(rtoSearch);
    }
  }

  const elementRef = useRef(null);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className='main-con'>
      {(ENVIRONMENT == 'production') && <Helmet
        script={
          [{ "src": "https://www.googletagmanager.com/gtag/js?id=" + GA_AW_CODE, "type": "text/javascript" },
          {
            "innerHTML": `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '`+ GA_AW_CODE + `');
        `, "type": "text/javascript"
          }
          ]
        }
      />}
      <Header />
      <div className='hero-img'>
        <div style={{ backgroundImage: `url(${img2})` }} className='hero-cn'>
          <div className='hero-inn'>
            <div className='hero-inner her_vrt'>


              <div className="sect_con_nav">
                <div className="sect_cn_bx">
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-center ">
                      <div className="main_nav_bx">
                        <div ref={elementRef} className='top_navscrl_sec' >
                          <ul className='d-flex' >
                            {sellCarTabs.map((tab, index) => {
                              return (<li onClick={() => { selectTab(index, selectedData) }} className={`${(selectedData.current_step == index) && "active-tiles"}`}><span>{tab}</span></li>);
                            })}
                          </ul>
                        </div>

                        <div className="nav_txt">
                          {(sellCarTabs[selectedData.current_step] == 'Brand') &&
                            <div>
                              <div className="top_sec_carsrch">
                                <input type="text" value={selectedData.reg_no?.toUpperCase().trim()} onChange={(e) => { dispatch(updateSellCarData({ ...selectedData, 'reg_no': e.target.value })) }} placeholder='Car Registration Number (Optional) eg. DL7CAXXXX' />
                                <h3>Select Your Car Brand</h3>
                                <input type="text" placeholder='Search Brands' onChange={(e) => handleSearchBrands(e.target.value)} />
                              </div>

                              <div className='brand_listingSec cars_filter'>
                                <div className='brand_tiles'>
                                  {
                                    filteredBrand.map((brandsData) => {
                                      return (
                                        <div className="brand_btns"  >
                                          <div className="brn_row_one d-flex justify-content-between">
                                            <div className={`brnd_ind_con ${selectedData.brand == brandsData.id && "brands-active"}`}
                                              onClick={() => { handleSelectBrand(brandsData.id, brandsData.name, true) }} data-brand_id={brandsData.id} data-brand_name={brandsData.name} data-brand_tiles="tilebrand" data-year_tiles="year_tiles" >
                                              <div>
                                                <img className='w-100 ' src={brandsData?.car_image} alt="logo" />
                                                <label data-brand_id={brandsData.id} data-brand_name={brandsData.name} data-year_tiles="year_tiles" className='brnd_label label-2' htmlFor="lang-2" > {brandsData.name}</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          }
                          {
                            (sellCarTabs[selectedData.current_step] == 'Year') &&
                            <div className="car_man_yr_con" id='man_yr' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-brand_tiles="tilebrand"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Select Car's Manufacturing Year
                                </h3>
                                {/* <input type="number" onChange={handleSearchYear} placeholder='Search Manufacturing year' /> */}
                              </div>
                              <div className='man_yr_listingSec cars_filter'>
                                {yearList.map(yearData => (
                                  <div className="man_yr_blw_sect" onClick={handleSelectYear}>
                                    <input type="hidden" name="lang" className="lang-lb-1" value={yearData} data-modals_tiles="modals_tiles" />

                                    <label data-modals_tiles="modals_tiles" className={`man_label m_lbl_label-1 ${selectedData.year == yearData && "brands-active"}`} htmlFor="lang-lb-1" > {yearData} </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'Model') &&
                            <div className="car_man_yr_con" id='car_models' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-year_tiles="year_tiles"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Select Car Model
                                </h3>
                                <input type="text" placeholder='Search car models' onChange={(e) => handleSearchModel(e.target.value)} />
                              </div>
                              <div className='model_listingSec cars_filter'>
                                {
                                  filteredModels.map((modelsData) => {
                                    return (
                                      <div className="man_yr_blw_sect">
                                        <div className={`car_mod car_mod_lbl_1 ${selectedData.model == modelsData.id && "brands-active"}`} onClick={() => { handleSelectModel(modelsData.id, modelsData.name, true) }} data-model_id={modelsData.id} data-model_name={modelsData.name} data-variant_tiles="var_tiles">

                                          <label data-model_id={modelsData.id} data-model_name={modelsData.name} data-variant_tiles="var_tiles" htmlFor="lang-lb-1" >{modelsData.name}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'Variant') &&
                            <div className="car_man_yr_con" id='car_models' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-modals_tiles="modals_tiles"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Select Car variant
                                </h3>
                                <input type="text" placeholder='Search car variants' onChange={(e) => handleSearchVariant(e.target.value)} />
                              </div>
                              <div className='variant_listingSec cars_filter'>
                                {
                                  filteredVariants.map((variantsData) => {
                                    return (
                                      <div className="man_yr_blw_sect" >
                                        <div className={`car_mod car_mod_lbl_1 ${selectedData.variant == variantsData.id && "brands-active"}`} onClick={() => { handleSelectVariant(variantsData.id, variantsData.name, true) }} data-variant_id={variantsData.id} data-variant_name={variantsData.name} data-fuels_tiles="fuels_tiles">

                                          <label data-variant_id={variantsData.id} data-variant_name={variantsData.name} data-fuels_tiles="fuels_tiles" htmlFor="car_mod_1"> {variantsData.name}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'Fuel') &&
                            <div className="car_man_yr_con" id='fuel_type' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-variant_tiles="var_tiles"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Fuel Type
                                </h3>
                                {/* <input type="text" placeholder='Search Fuel Types' onChange={(e) => handleSearchFuels(e.target.value)}/> */}
                              </div>
                              <div className='fuels_listingSec cars_filter'>
                                {
                                  filteredFuels.map((fuelsData) => {
                                    return (
                                      <div className="man_yr_blw_sect">
                                        <div className={`flttp flttp_lbl_1 ${selectedData.fuel == fuelsData.id && "brands-active"}`} onClick={() => { handleSelectFuels(fuelsData.id) }} data-rto_tiles="rtoActive">

                                          <label data-fuels_id={fuelsData.id} data-rto_tiles="rtoActive" htmlFor="car_mod_1"> {fuelsData.name}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'RTO') &&
                            <div className="car_man_yr_con" id='rto_states' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-fuels_tiles="fuels_tiles"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Enter RTO Code
                                </h3>
                                <input type="text" value={rtoSearchTxt} placeholder='Search RTO Codes' onChange={(e) => { setRtoSearchTxt(e.target.value); handleSearchRto(e.target.value) }} />
                              </div>
                              <div className='rto_listingSec cars_filter'>
                                {
                                  filteredRto.map((rtolistData) => {
                                    return (
                                      <div className="man_yr_blw_sect">
                                        <div className={`rto_label rto_label_1 ${selectedData.rto == rtolistData.id && "brands-active"}`} onClick={handleSelectRto} data-rtolist_id={rtolistData.id} data-rtolist_name={rtolistData.rto_code} data-state_tiles="stateActive">

                                          <label data-rtolist_id={rtolistData.id} data-state_tiles="stateActive" htmlFor="rto-1">{rtolistData.rto_code}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'State') &&
                            <div className="car_man_yr_con" id='states' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-rto_tiles="rtoActive"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Select State
                                </h3>
                                <input type="text" placeholder='Search States' onChange={(e) => handleSearchState(e.target.value)} />
                              </div>
                              <div className='states_listingSec cars_filter'>
                                {
                                  filteredstates.map((statesData) => {
                                    return (
                                      <div className="man_yr_blw_sect" >
                                        <div className={`state_stl state_stl_1 ${selectedData.state == statesData.id && "brands-active"}`} onClick={() => { handleSelectState(statesData.id, 0) }} data-state_id={statesData.id} data-state_name={statesData.name} data-city_tiles="cityActive">

                                          <label data-state_id={statesData.id} data-state_name={statesData.name} data-city_tiles="cityActive" htmlFor="state_1">{statesData.name}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }

                          {(sellCarTabs[selectedData.current_step] == 'City') &&
                            <div className="car_man_yr_con" id='city_location' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-state_tiles="stateActive"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Select your city by location
                                </h3>
                                <input type="text" placeholder='Search Cities' onChange={(e) => handleSearchCity(e.target.value)} />
                              </div>
                              <div className='city_listingSec cars_filter'>
                                {
                                  filteredcity.map((citiesData) => {
                                    return (
                                      <div className="man_yr_blw_sect" >
                                        <div className={`city_lbl city_lbl_1 ${selectedData.city == citiesData.id && "brands-active"}`} onClick={handleSelectCities} data-cities_id={citiesData.id} data-cities_name={citiesData.name} data-kms_tiles="kmsActive">

                                          <label data-cities_id={citiesData.id} data-cities_name={citiesData.name} data-kms_tiles="kmsActive" htmlFor="city_1"> {citiesData.name}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }
                          {(sellCarTabs[selectedData.current_step] == 'Kms') &&
                            <div className="car_man_yr_con" id='km_driven' >
                              <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-city_tiles="cityActive"><FaArrowLeft /></span>
                              <div className="man_yr_top_sect">
                                <h3>
                                  Kilometers Driven
                                </h3>
                              </div>
                              <div className="man_yr_blw_sect kms_listingSec cars_filter">
                                {
                                  kilometerList.map((kilometerData) => {
                                    return (
                                      <div className="man_yr_blw_sect" >
                                        <div className={`lbl_km_drv km_driv_lbl_1 ${selectedData.km_driven == kilometerData.value && "brands-active"}`} onClick={handleKilometerChange} data-kms_id={kilometerData.value} data-owners_tiles="ownersActive">

                                          <label data-kms_id={kilometerData.value} htmlFor="state_1">{kilometerData.label}</label>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }
                          {(sellCarTabs[selectedData.current_step] == 'Owner') &&
                            <div>
                              <div className="car_man_yr_con" id='km_driven' >
                                <span onClick={() => { selectTab(selectedData.current_step - 1, selectedData); handleHorizantalScroll(elementRef.current, 25, 130, -10); }} data-kms_tiles="kmsActive"><FaArrowLeft /></span>
                                <div className="man_yr_top_sect">
                                  <h3>
                                    Owner Serial
                                  </h3>
                                </div>
                                <div className="man_yr_blw_sect kms_listingSec cars_filter">
                                  {
                                    ownerList.map((ownerListData) => {
                                      return (
                                        <div className="man_yr_blw_sect" >
                                          <div className={`lbl_km_drv km_driv_lbl_1 ${selectedData.owner == ownerListData.value && "brands-active"}`} onClick={handleOwnerChange} data-owner_label={ownerListData.label} data-owner_id={ownerListData.value}>
                                            <label data-owner_id={ownerListData.value} htmlFor="state_1">{ownerListData.label}</label>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              <div className='book_hm_inspction'>
                                <Button className='mid_size_secbtn modal_btn' onClick={() => { validateEnqForm() }}>GET CAR PRICE</Button>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hero-title_1'>
                <h1>Sell your PREMIUM LUXURY car</h1>
                <p>Within 24 hours at the Highest Price</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size='lg' isOpen={modal} toggle={() => setmodal(!modal)} >
        <ModalHeader toggle={() => setmodal(!modal)} >
          <h3>Enter your Mobile Number to unlock car price</h3>
        </ModalHeader>
        <ModalBody>
          <div className='modalbod' >
            <div className='mod_bod_con' >
              <div className='modal_sec' >
                <h3>{selectedData.year} - {selectedData.brand_name} {selectedData.model_name} {selectedData.variant_name}</h3>
                <span>{selectedData.km_driven} KMs</span> <br />
              </div>
              <div className='modal_sec' >
                <h4><strong>Last step</strong> to unlock the price</h4>
                <label htmlFor="">Mobile Number</label> <br />
                <input className='mod_mob_num' type="number" value={mobile} onChange={(e) => { setMobile(e.target.value) }} placeholder='Please enter your 10 digit mobile number' /> <br />
                <a className="send-otp-anc" onClick={(e) => { e.preventDefault(); sendOtp() }} href="#.">{otpLabel}</a>
                <input className='mod_mob_num' type="number" value={otpNum} onChange={(e) => { setOtpNum(e.target.value) }} placeholder='Enter OTP' /> <br />
              </div>
              <div className='modal_sec' >
                <div className='mt_10'  >
                  <span className='algn_txt' > <input checked={true} type="checkbox" /> Send Update On <FaWhatsapp /> Whatsapp</span> <br />
                </div>
                <div className='mt_10' >
                  <span className='algn_txt'> <input checked={true} type="checkbox" /> I agree to <em> <a href="https://cars360.co/terms-conditions.php"> term & condition </a> </em> and <em> <a href="https://cars360.co/privacy.php"> privacy policy</a> </em> </span> <br />
                </div>
              </div>
              <Button onClick={() => { verifyOtp() }} className='mid_size_secbtn mod_pop_btn_cntr'>GET CAR PRICE</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className=' dad_con sell_3_steps'>
        <div className='div_for_padt'>
          <div className='car_proc_sell_title'>
            <h3 className='bef_elem'>Sell your PREMIUM LUXURY car in 3 easy steps!</h3>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="card sell-car" >
                  <img className="card-img-top" src="./sell-car-img/1-cr.jpg" alt="Card-cap" />
                  <h4 className='stprt'>1</h4>
                  <div className="card-body">
                    <h5 className="card-title">Schedule an inspection</h5>
                    <p className="card-text">Call us on +91 9910080365 or use our online form to schedule an inspection. You can opt for a meeting at a place of your convenience.</p>

                  </div>
                </div>
              </div>
              <div className="col-sm ">
                <div className="card sell-car" >
                  <img className="card-img-top" src="./sell-car-img/2-cr.jpg" alt="Card-cap" />
                  <h4 className='stprt'>2</h4>
                  <div className="card-body">
                    <h5 className="card-title">Get a free evaluation</h5>
                    <p className="card-text">Our team of trained professionals will inspect your car and verify the documents of your car. Once the inspection is completed, we will provide you with an estimate valuation of your car.</p>

                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card sell-car" >
                  <img className="card-img-top" src="./sell-car-img/3-cr.jpg" alt="Card-cap" />
                  <h4 className='stprt'>3</h4>
                  <div className="card-body">
                    <h5 className="card-title">Sell your premium car</h5>
                    <p className="card-text">Once you accept the offered price don't worry, we'll take care of all the paperwork and RC transfer.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row_sell_step'>
            {/* <div className='row_sell_step-cons'>
              <div className='sel-cr-imgs'>
                <img src="./sell-car-img/1-cr.jpg" alt="" />
              </div>
              <h4 className='stprt'>1</h4>
              <div className='sel_innr_content'>
                <div style={{ padding: '18px', paddingBottom: '42px' }}>
                  <h3>Schedule an inspection</h3>
                  <p>Call us on +91 9910080365 or use our online form to schedule an inspection. You can opt for a meeting at a place of your convenience.</p>
                </div>
              </div>
            </div> */}
            {/* <div className='row_sell_step-cons'>
              <div className='sel-cr-imgs'>
                <img src="./sell-car-img/2-cr.jpg" alt="" />
              </div>
              <h4 className='stprt'>2</h4>
              <div className='sel_innr_content'>
                <div style={{ padding: '18px' }}>
                  <h3>Get a free evaluation</h3>
                  <p>Our team of trained professionals will inspect your car and verify the documents of your car. Once the inspection is completed, we will provide you with an estimate valuation of your car.</p>
                </div>
              </div>
            </div> */}

            {/* <div className='row_sell_step-cons'>
              <div className='sel-cr-imgs'>
                <img src="./sell-car-img/3-cr.jpg" alt="" />
              </div>
              <h4 className='stprt'>3</h4>
              <div className='sel_innr_content'>
                <div style={{ padding: '18px', paddingBottom: '66px' }}>
                  <h3>Sell your car</h3>
                  <p>Once you accept the offered price don't worry, we'll take care of all the paperwork and RC transfer. </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <section className='service-center no-gap-mob commonm-gap pb-0'>
        <div className='inner-sec-img'>
          <div class="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className='text-white service-head '>Book your car inspection right at your doorstep.  </h2>

                <p className='text-white text-summary'>In the realm of premium cars, luxury isn't just something you own, it's something you live. We provide the opportunity for you to immerse yourself in that experience right from the comfort of your own home.</p>

                <ul style={{paddingLeft: "0",margin: "0"}}>
                  <li className='text-white li-set'><FaCheck/> Book your FREE car inspection</li>
                  <li className='text-white li-set'><FaCheck/> Receive the top offer following inspection</li>
                  <li className='text-white li-set'><FaCheck/> Enjoy absolute peace of mind</li>
                </ul>

                <div class="actions-btn">
                  <a href="#" className="btn center  arrow-style blueBdr text-white"><span>Book Appointment Now</span></a>
                
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </section>


      <CarsDetailsSection />
      <BlogSection />
      <TestimonialSection />
      <div className='dad_con'>
        <div className='div_for_pad_acc'>
          <div class="container-fluid">
            <div class="row">

              <div class="col-md-6">
                <h3 className='form-title'>Frequently Asked Questions</h3>
                <Accordion className='accordian' defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Why is the car360 is best place to sell car ?</Accordion.Header>
                    <Accordion.Body>
                      With hassle-free car selling experience, we are providing our customers transparent and secured dealing. We value strong relationships build on trust and transparency.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Can i sell a car which is having loan on it ?</Accordion.Header>
                    <Accordion.Body>
                      Our executive will take you through the process, get all the required document signed and NOC issued.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Do i need to transfer RC in RTO ?</Accordion.Header>
                    <Accordion.Body>
                      As Soon as your car is sold you don’t have to worry about the RC-transfer and paper work, it’s on us.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Does car 360 buy commercial cars</Accordion.Header>
                    <Accordion.Body>
                      Buy used cars in Delhi from our wide range of car listings. Get best deals/offers on good condition, verified second hand cars for sale in Delhi/NCR
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div class="col-md-6 ">
                <h3 className='form-title'>Contact Us</h3>
                <div className='card form-data'>
                  <Form >
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Your Name</Form.Label>
                      <Form.Control required type="text" value={contactDetail.name} placeholder="Your Name"  name='name' onChange={setContactValue}/>
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid Name
                       </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control required type="email" value={contactDetail.email} placeholder="Enter email" name='email' onChange={setContactValue} />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid email
                       </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control required type="number" value={contactDetail.phone} placeholder="Mobile number" name='phone' onChange={setContactValue} />
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid number
                       </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as='textarea' required value={contactDetail.message} placeholder="Message" name='message' onChange={setContactValue}/>
                      <Form.Control.Feedback type="invalid">
                        Please Enter a valid Message
                       </Form.Control.Feedback>
                    </Form.Group>
                    <Button type="button" onClick={sumbitContactForm}>
                      Submit
                    </Button>
                    {/* <Button type="submit">
                      Submit
                    </Button> */}
                  </Form>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className='dad_con section_car'>
        <div className='only_carcon'>
          <h1> Easiest way to <br /> sell your premium car online!</h1>
          <a href="#">
            <button className='mid_size_secbtn'>GET CAR PRICE</button>
          </a>
        </div>
        <div className='only_carcon_img'>
          <img style={{width: '650px', height: '358px'}} src="./section_img/cars360car.png" alt="" />
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default LandingPages
