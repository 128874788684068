import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPages from './pages/LandingPages';
import SellCar from './pages/SellCar';
import Thankyou from './pages/Thankyou';
import About from './pages/About';

const Routescomponent = () => {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPages />}/>
        <Route exact path='/sellcar' element={<SellCar />}/>
        <Route exact path='/about' element={<About />}/>
        <Route exact path='/thankyou' element={<Thankyou />}/>
        <Route exact path='/*' element={ <Navigate to='/' /> }/>
      </Routes>
    </Router>
    </>
  )
}

export default Routescomponent
