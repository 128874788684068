import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'
import { ENVIRONMENT, GA_MEASUREMENT_ID } from './env';
// import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));

if(ENVIRONMENT == 'production') {
  //Initialize GA4
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <App />
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

const SendAnalytics = () => {
  if(ENVIRONMENT == 'production') {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
