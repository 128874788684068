import React from 'react'

function SellCar() {
  return (
    <div>
        Sell Car Page
    </div>
  )
}

export default SellCar