import './App.css';
import './style.css';
import './responsive.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routescomponent from './Routescomponent';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routescomponent />
      </PersistGate>
    </Provider>
  );
}

export default App;